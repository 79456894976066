import { request as axios, request } from '@/util/request';
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');
const API ={
    channel:'/boss/factory/channel'
}
async function getChannel(send_data: any ={} , current: number = 1) {
    clean(send_data);
    const _send_data = {
      ...send_data,
      current,
    };
    const url = getUrl(API.channel, _send_data);
    const res = await axios.get(url)    
    if(res.data.length === 0) return res
    const { data : { detail } } = res
    res.data.detail = detail.map((item)=>{
        return {
            ...item,
            status: item.status === 1 ? true : false,
        }
    })
    return res;
}
async function postChannel(params: any) {
    return request.post(API.channel,params)
}
async function putChannel(params: any,id?: number) {
    return request.put(API.channel +'/'+ id,params)
}
async function putChannelStatus(params: any,id?: number) {
    return request.put('/boss/factory/channel/status/'+id,params)
}
export {
    getChannel,
    postChannel,
    putChannel,
    putChannelStatus
}
