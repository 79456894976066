import { request as axios } from '@/util/request';

const { VUE_APP_JSON: FCAPI } = process.env;
const API = {
  region_list: `${FCAPI}/data/region.json`,
  region: '/boss/warehouse/region',
};

function formatRegion(remote: any = {}): RemotePagination<AreaTableData> {
  return {
    current: remote.current,
    limit: remote.limit,
    detail: remote.detail,
    total: remote.total,
    last: remote.last,
  };
}

function formatRegionData(remote: any = {}): AreaTableData {
  return {
    id: remote.id,
    id_text: `A${remote.id}`,
    name: remote.region_name,
    include_area: remote.region_area.split(',').join('、'),
    include_area_id: remote.region_area_id,
  };
}

function formatRegionList(data: any = []) {
  return data.map((item: any) => {
    return {
      id: item.id,
      name: item.region_name,
    };
  });
}

async function getRegionList() {
  return await axios.get(`${API.region_list}`);
}

async function queryRegionAndPaginate(current: number = 1, is_paginate: boolean) {
  const res = await axios.get(API.region, {
    params: {
      current,
      is_page: is_paginate ? 1 : null,
    },
  });
  if (res.data.length === 0) {
    return [];
  }
  const remote_region = formatRegion(res.data);
  remote_region.detail = remote_region.detail.map((item: any) => {
    return formatRegionData(item);
  });
  return remote_region;
}

/**
 * 查询区域列表
 */
async function queryRegionList() {
  const res = await axios.get(API.region);
  return formatRegionList(res.data);
}

async function addRegion(region_name: string, region_area: string, region_area_id: string) {
  return await axios.post(API.region, {
    region_name,
    region_area,
    region_area_id,
  });
}

async function updateRegion(
  id: number,
  region_name: string,
  region_area: string,
  region_area_id: string
) {
  return await axios.put(`${API.region}/${id}`, {
    region_name,
    region_area,
    region_area_id,
  });
}

async function deleteRegion(id: number) {
  return await axios.delete(`${API.region}/${id}`);
}

export default getRegionList;
export {
  getRegionList,
  queryRegionList,
  queryRegionAndPaginate,
  addRegion,
  updateRegion,
  deleteRegion,
};
