import { getRegionList } from '@/api/repository/region';
import storage from 'baimahu/storage';

const overseas: Region[] = [
  {
    id: '1001',
    pid: 0,
    deep: 0,
    ext_name: '新加坡',
    childs: [],
  },
  {
    id: '1002',
    pid: 0,
    deep: 0,
    ext_name: '马来西亚',
    childs: [],
  },
  {
    id: '1003',
    pid: 0,
    deep: 0,
    ext_name: '澳大利亚',
    childs: [],
  },
  {
    id: '1004',
    pid: 0,
    deep: 0,
    ext_name: '新西兰',
    childs: [],
  },
  {
    id: '1005',
    pid: 0,
    deep: 0,
    ext_name: '美国',
    childs: [],
  },
  {
    id: '1006',
    pid: 0,
    deep: 0,
    ext_name: '加拿大',
    childs: [],
  },
  {
    id: '1007',
    pid: 0,
    deep: 0,
    ext_name: '韩国',
    childs: [],
  },
  {
    id: '1008',
    pid: 0,
    deep: 0,
    ext_name: '日本',
    childs: [],
  },
  {
    id: '1009',
    pid: 0,
    deep: 0,
    ext_name: '其他',
    childs: [],
  },
];
const _area_list: any = {
  2: {
    id: 2,
    name: '华东',
  },
  3: {
    id: 3,
    name: '华北',
  },
  4: {
    id: 4,
    name: '华中',
  },
  5: {
    id: 5,
    name: '华南',
  },
  6: {
    id: 6,
    name: '东北',
  },
  7: {
    id: 7,
    name: '西北',
  },
  8: {
    id: 8,
    name: '西南',
  },
  9: {
    id: 9,
    name: '港澳台',
  },
  10: {
    id: 10,
    name: '海外',
  },
};

let region_list: any = [];

// 获取远程 region.json
async function setRegion() {
  if (storage.getItem('region_list')) {
    region_list = [...storage.getItem('region_list'), ...overseas];
  } else {
    const remote_region_list: any = await getRegionList();
    region_list = [...remote_region_list, ...overseas];
    storage.setItem('region_list', remote_region_list);
  }
}

// 生成 area_list
function genAreaList() {
  const _municipality = [11, 12, 31, 50]; // 直辖市
  const _area = _area_list;
  for (const key in _area) {
    _area[key] = {
      ..._area[key],
      checked: false,
      indeterminate: false,
      childs: [],
      checked_list: new Map(),
      children_indeterminate: new Map(),
    };
  }
  setRegion();
  region_list.map((item: Region) => {
    const _id = parseInt(item.id as string, 10);
    if (_id >= 11 && _id <= 15) {
      item.pid = 3;
    } else if (_id >= 21 && _id <= 23) {
      item.pid = 6;
    } else if (_id >= 31 && _id <= 37) {
      item.pid = 2;
    } else if (_id >= 41 && _id <= 43) {
      item.pid = 4;
    } else if (_id >= 44 && _id <= 46) {
      item.pid = 5;
    } else if (_id >= 50 && _id <= 54) {
      item.pid = 8;
    } else if (_id >= 61 && _id <= 65) {
      item.pid = 7;
    } else if (_id === 71) {
      item.pid = 9;
    } else if (_id >= 81 && _id <= 82) {
      item.pid = 9;
      item.is_show_arrow = false; // 港澳
    } else if (_id >= 1001 && _id <= 1009) {
      item.pid = 10;
      item.is_show_arrow = false; // 海外
    }
    if (_municipality.indexOf(_id) > -1) {
      item.is_show_arrow = false;
    }
    if (_area_list[item.pid]) {
      const _item = {
        ...item,
        checked_list: new Map(),
        checked: false,
        indeterminate: false,
        is_show_popup: false,
      };
      _area_list[item.pid].childs.push(_item);
    }
  });
  return _area_list;
}

const area_list = genAreaList(); // 经过格式化后的区域列表

export default area_list;
export { area_list, setRegion };
